<template>
  <!--  <div class="card bg-secondary">-->
  <div class="card bg-white" :class="{'opacity-50': quoteDisabled}">
    <div class="card-body">
      <div class="card-top-row">
        <div class="left">
          <h5 class="card-title">
            {{ quoteProviderJobMember.user_role.name }}
          </h5>
        </div>
        <div class="right">
          <span v-if="quote.quote_type == 'fixed-price'">Fixed Price</span>
          <span v-if="quote.quote_type == 'hourly'">Hourly Rate</span>
          <span v-if="quote.quote_type == 'monthly-fee'">Monthly Fee</span>
        </div>
      </div>

      <div class="date-provided">
        <div :key="timeAgoRefreshKey">
          <b>Quote Created:</b> {{ displayTimeAgo(quote.created_at) }}
        </div>
      </div>

      <div class="provider-details">
        <div>
          <b>Company Name:</b>
          {{ quoteProviderJobMember.user_role.company_name ? quoteProviderJobMember.user_role.company_name : '-' }}
        </div>
      </div>

      <div class="quote-details">
        <div>
          <b>Total Fee:</b> {{ formatCostInPence(quote.total_fee) }}
        </div>

        <!-- Hourly -->
        <div v-if="quote.quote_type === 'hourly'">
          <b>Hourly Rate: </b> {{ formatCostInPence(quote.hourly_rate) }}
        </div>
        <div v-if="quote.quote_type === 'hourly'">
          <b>Estimated Hours: </b> {{ quote.estimated_hours }}
        </div>
        <!-- / Hourly -->

        <!-- Monthly fee -->
        <div v-if="quote.quote_type === 'monthly-fee'">
          <b>Monthly Fee: </b> {{ formatCostInPence(quote.monthly_fee) }}
        </div>
        <div v-if="quote.quote_type === 'monthly-fee'">
          <b>Start Date: </b> {{ quote.start_date }}
        </div>
        <div v-if="quote.quote_type === 'monthly-fee'">
          <b>Minimum Term: </b> {{ quote.minimum_term_months }} {{
            quote.minimum_term_months === 1 ? 'month' : 'months'
          }}
        </div>
        <!-- / Monthly fee -->
      </div>

      <div class="quote-details">
        <div>
          <b>Deposit Required:</b> {{ formatCostInPence(quote.deposit_required) }}
        </div>
      </div>

      <div class="vat-rate">
        <div>
          <b>VAT Rate:</b> {{ quote.vat_rate }}%
        </div>
      </div>

      <div v-if="quote.estimated_completion_date" class="estimated-completion-date">
        <div>
          <b>Estimated Completion:</b> {{ formatDate(quote.estimated_completion_date) }}
        </div>
      </div>

      <div class="notes" v-if="quote.notes">
        <div>
          <b>Notes:</b><br>
          <div v-html="quote.notes"></div>
        </div>
      </div>

      <div class="terms"
           v-if="quoteProviderJobMember && quoteProviderJobMember.user_role && quoteProviderJobMember.user_role.terms_and_conditions_url">
        <div>
          <a class="text-decoration-none" target="_blank"
             :href="quoteProviderJobMember.user_role.terms_and_conditions_url">View terms and conditions</a>
        </div>
      </div>

    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <job-member-avatars :members="[quoteProviderJobMember]"></job-member-avatars>
        </div>
        <div class="flex-shrink-1 text-right">

          <!-- Discuss Quote -->
          <div v-if="canAccept && !isConfirmingAcceptance" class="d-inline-block">
            <button type="button" class="btn btn-primary me-2" :disabled="quoteDisabled"
                    @click.prevent="$emit('discuss-quote')">
              <i class="fi-chat-circle me-2"></i>
              Discuss Quote
            </button>
          </div>
          <!-- / Discuss Quote -->

          <!-- Accept -->
          <div v-if="canAccept && !isConfirmingAcceptance" class="d-inline-block">
            <button @click.prevent="attemptAccept" :disabled="quoteDisabled"
                    :class="{'opacity-50': isAccepting}"
                    type="button" class="btn btn-primary">
              <i v-if="!isAccepting" class="fi-check me-2"></i>
              <spinner v-else class="spinner-border-sm me-2"></spinner>
              Accept
            </button>
          </div>
          <!-- Accept -->

          <div class="text-right mb-3" v-if="canAccept && isConfirmingAcceptance">
            <b>
              This will instruct the work and all other quotes will be rejected
            </b>
          </div>

          <!-- Accept terms and conditions (if needed) -->
          <div v-if="canAccept && isConfirmingAcceptance && hasTermsAndConditions">
            <div class="form-check form-check-inline mb-4">
              <input v-model="termsAccepted"
                     value="personal"
                     class="form-check-input" id="termsAccepted" type="checkbox" name="termsAccepted">
              <label class="form-check-label" for="termsAccepted">I accept the professional's <a class="text-decoration-none"
                                                                                           target="_blank"
                                                                                           :href="quoteProviderJobMember.user_role.terms_and_conditions_url">terms
                and conditions</a></label>
            </div>
          </div>
          <!-- / Accept terms and conditions (if needed) -->

          <!-- Confirm acceptance: Accept -->
          <div v-if="canAccept && isConfirmingAcceptance" class="d-inline-block">
            <button type="button" class="btn btn-primary me-2" :disabled="isAccepting || !canAcceptQuote"
                    @click="accept">
              <i v-if="!isAccepting" class="fi-check me-2"></i>
              <spinner v-else class="spinner-border-sm me-2"></spinner>
              Proceed
            </button>
          </div>
          <!-- / Confirm acceptance: Accept -->

          <!-- Confirm acceptance: Cancel -->
          <div v-if="canAccept && isConfirmingAcceptance" class="d-inline-block">
            <button type="button" class="btn btn-danger me-2" :disabled="isAccepting"
                    @click="cancelConfirmingAcceptance">
              <i class="fi-x me-2"></i>
              Cancel
            </button>
          </div>
          <!-- / Confirm acceptance: Cancel -->

          <!-- Status -->
          <div v-if="!canAccept">
            <span v-if="isAccepted" class="badge bg-success">Accepted</span>
            <span v-if="isRejected" class="badge bg-danger">Rejected</span>
            <span v-if="isPending" class="badge bg-info">Awaiting Client Decision</span>
          </div>
          <!-- / Status -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobMemberAvatars from "../../Index/ResultCards/JobMemberAvatars";
import Display from "../../../../mixins/Display";
import Dates from "../../../../mixins/Dates";
import Spinner from "../../../../components/Spinner";
import jobs from "../../../../api/jobs";
import Forms from "../../../../mixins/Forms";
import TimeAgoRefresher from "../../../../mixins/TimeAgoRefresher";
import {mapGetters, mapActions} from 'vuex';

import {useToast} from 'vue-toastification';

const toast = useToast();

export default {
  name: "JobQuoteCard",
  props: ['job', 'quote'],
  mixins: [Display, Forms, Dates, TimeAgoRefresher],
  components: {JobMemberAvatars, Spinner},
  data() {
    return {
      isAccepting: false,
      serverErrors: [],
      termsAccepted: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      isClient: 'isClient',
      isAdvisor: 'isAdvisor'
    }),

    ...mapGetters('jobs', {
      confirmingAcceptanceForQuoteId: 'confirmingAcceptanceForQuoteId'
    }),

    canAcceptQuote() {
      if (this.hasTermsAndConditions) {
        return this.termsAccepted;
      }
      return true;
    },

    quoteDisabled() {
      return (this.confirmingAcceptanceForQuoteId && this.confirmingAcceptanceForQuoteId !== this.quote.id);
    },

    isConfirmingAcceptance() {
      return (this.confirmingAcceptanceForQuoteId && this.confirmingAcceptanceForQuoteId == this.quote.id);
    },

    quoteProviderJobMember() {
      return _.find(this.job.job_members, {'user_role_id': this.quote.provider_user_role_id});
    },

    areClientDetailsProvided() {
      return (this.job.client_name && this.job.client_email);
    },

    canAccept() {
      if (this.isAdvisor) {
        // return (!this.areClientDetailsProvided && this.isPending);
        return (this.isPending);
      }
      return this.isClient && this.job.accepted_job_quote_id === null;
    },

    isAccepted() {
      return this.job.accepted_job_quote_id === this.quote.id;
    },

    isRejected() {
      return !this.isAccepted && (this.job.status > 0);
    },

    isPending() {
      return !this.isAccepted && (this.job.status === 0);
    },

    hasTermsAndConditions() {
      return (this.quoteProviderJobMember && this.quoteProviderJobMember.user_role && this.quoteProviderJobMember.user_role.terms_and_conditions_url);
    }
  },
  methods: {
    ...mapActions('jobs', {
      setConfirmingAcceptanceForQuoteId: 'setConfirmingAcceptanceForQuoteId',
      clearConfirmingAcceptanceForQuoteId: 'clearConfirmingAcceptanceForQuoteId'
    }),

    // attemptAccept() {
    //   let vm = this;
    //   swal({
    //     title: "Are you sure you want to accept this quote?",
    //     text: "All other quotes will be automatically rejected",
    //     icon: "warning",
    //     buttons: {
    //       cancel: true,
    //       confirm: "Accept"
    //     }
    //   }).then((shouldAccept) => {
    //     if (shouldAccept) {
    //       vm.accept();
    //     }
    //   });
    // },

    attemptAccept() {
      this.termsAccepted = false;
      this.setConfirmingAcceptanceForQuoteId(this.quote.id);
    },

    cancelConfirmingAcceptance() {
      this.clearConfirmingAcceptanceForQuoteId();
    },

    accept() {
      if (!this.canAccept) {
        return false;
      }
      let vm = this;
      vm.isAccepting = true;
      jobs.acceptQuote(this.job.id, this.quote.id).then(r => {
        vm.isAccepting = false;
        toast.success('Quote accepted successfully');
        vm.$emit('quote-accepted');
      }).catch(e => {
        console.log(e);
        vm.setAndNotifyErrors(e);
        vm.isAccepting = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  font-size: 15px;

  .quote-details, .provider-details, .notes, .vat-rate, .terms, .date-provided, .estimated-completion-date {
    div {
      display: inline-block;
      padding-top: 8px;
      padding-right: 20px;

      &:last-child {
        padding-right: 0 !important;
      }

      b {
        color: #1f1b2d;
      }
    }
  }

  .card-top-row {
    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }
  }
}

.text-right {
  text-align: right;
}
</style>