<template>
  <div class="read-indicator" :class="{'has-profiles': conversationMembersToShow.length}">
    <div class="user-role-indicator" v-for="member in conversationMembersToShow">
<!--      <img width="25" class="rounded-circle" :src="getAvatarSrc(member.user_role)" alt="Avatar"/>-->
      <user-role-avatar width="25" class="rounded-circle"
                        :user-role="member.user_role"></user-role-avatar>
    </div>
  </div>
</template>

<script>
import Images from "../../../../../mixins/Images";
import {mapGetters} from "vuex";
import UserRoleAvatar from "../../../../../components/UserRoleAvatar";

export default {
  name: "ReadIndicator",
  components: {UserRoleAvatar},
  props: ['conversation', 'message', 'newerMessage', 'conversationMembers'],
  mixins: [Images],
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    conversationMembersToShow() {
      if (this.message && this.newerMessage) {
        return _.filter(this.conversationMembers, member => {
          let memberCloned = {...member};
          if (memberCloned.last_read_at == null) {
            memberCloned.last_read_at = this.conversation.created_at;
          }
          // If me
          if (memberCloned.user_role_id == this.userRole.id) {
            memberCloned.last_read_at = window.moment();
          }

          let lowerLim = window.moment(this.message.created_at);
          let upperLim = window.moment(this.newerMessage.created_at);
          let lastReadAt = window.moment(memberCloned.last_read_at);
          if (
              lowerLim.isValid()
              && upperLim.isValid()
              && lastReadAt.isValid()
          ) {
            return lastReadAt.isBetween(lowerLim, upperLim)
          }
          return false;
        })
      } else if (this.message && !this.newerMessage) {
        return _.filter(this.conversationMembers, member => {
          let memberCloned = {...member};
          if (memberCloned.last_read_at == null) {
            memberCloned.last_read_at = this.conversation.created_at;
          }

          let lowerLim = window.moment(this.message.created_at);
          let lastReadAt = window.moment(memberCloned.last_read_at);
          if (
              lowerLim.isValid()
              && lastReadAt.isValid()
          ) {
            return lastReadAt.isSameOrAfter(lowerLim);
          }
          return false;
        })
      } else if (!this.message && this.newerMessage) {
        return _.filter(this.conversationMembers, member => {
          let memberCloned = {...member};
          if (memberCloned.last_read_at == null) {
            memberCloned.last_read_at = this.conversation.created_at;
          }

          let upperLim = window.moment(this.newerMessage.created_at);
          let lastReadAt = window.moment(memberCloned.last_read_at);
          if (
              upperLim.isValid()
              && lastReadAt.isValid()
          ) {
            return lastReadAt.isSameOrBefore(upperLim);
          }
          return false;
        })
      } else {
        return [];
      }
    }
  }
}
</script>

<style scoped lang="scss">
.read-indicator {
  &.has-profiles {
    margin-top: 10px;
  }

  display: flex;
  justify-content: flex-end;

  .user-role-indicator {
    margin-left: 6px;
  }
}
</style>