let fileDownload = require('js-file-download');
import axios from 'axios';

import {useToast} from "vue-toastification";
const toast = useToast();

export default {
    methods: {
        downloadConversationFile(file, jobId) {
            let path = window.API_BASE + '/jobs/' + jobId + '/conversations/' + file.conversation_id + '/files/' + file.id;
            axios({
                url: path,
                method: 'GET',
                responseType: 'blob'
            }).then(r => {
                fileDownload(r.data, file.filename);
            }).catch(e => {
                console.log(e);
                toast.error('Error downloading file');
            });
        }
    }
}