<template>
  <div class="message-row" :class="{'sent-by-me': isSentByMe, 'sent-by-syndesi': isSentBySyndesi, 'pending': isPending}">
    <div class="message" :class="{'clickable': message.navigate_to !== null}" @click.prevent="handleMessageClicked">
      <div class="message-border">
        <div class="sent-by" v-if="!isSentByMe && !isSentBySyndesi">
          {{ getNameFromUserRoleId(message.sent_by_user_role_id, message.sent_from_syndesi) }}
        </div>
        <div>
          {{ message.message }}
        </div>
        <div class="files" v-if="message.conversation_files && message.conversation_files.length">
          <a href="#" @click.prevent="downloadConversationFile(file, jobId)" class="badge bg-primary file text-wrap mb-2"
             v-for="(file, i) in message.conversation_files" :key="i">
            {{ file.filename }}
          </a>
        </div>
      </div>
      <div class="timestamp" :key="timeAgoRefreshKey">{{ displayTimeAgo(message.created_at) }}</div>
    </div>
  </div>
</template>

<script>
import Dates from "../../../../../mixins/Dates";
import Conversations from "../../../../../mixins/Conversations";
import TimeAgoRefresher from "../../../../../mixins/TimeAgoRefresher";
import {mapGetters} from "vuex"

export default {
  name: "Message",
  props: ['message', 'jobId', 'conversationMembers'],
  mixins: [Dates, Conversations, TimeAgoRefresher],
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    isSentByMe() {
      return this.message.sent_by_user_role_id === this.userRole.id
    },

    isSentBySyndesi() {
      return this.message.sent_from_syndesi;
    },

    isPending() {
      return (this.message.pending && this.message.pending === true);
    }
  },
  methods: {
    getNameFromUserRoleId(userRoleId, sentFromSyndesi=false) {
      if (userRoleId == null && sentFromSyndesi == true) {
        return 'Syndesi';
      }
      let member = _.find(this.conversationMembers, {'user_role_id': userRoleId});
      if (member) {
        return member.user_role.name;
      }
      return 'Unknown';
    },

    handleMessageClicked() {
      if (this.message.navigate_to == 'quotes') {
        this.$emit('navigate-to-quotes');
      }
      if (this.message.navigate_to == 'job-details') {
        this.$emit('navigate-to-job-details');
      }
      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.message-row {
  overflow-wrap: break-word;
  margin-top: 10px;
  display: flex;

  .sent-by {
    color: #000;
    margin-bottom: 5px;
  }

  &.sent-by-syndesi {
    justify-content: center;
    text-align: center;

    .timestamp {
      text-align: center;
      margin-top: 2px;
    }

    .message-border {
      //background: #fd5631;
      //border-color: #fd6531;
      //color: #fff;

      //background: #B9E7DD;
      //border-color: #B9E7DD;

      background: #efecf3;
      border-color: #efecf3; 

      color: #000;
      text-align: center;
    }
  }

  &.sent-by-me {
    justify-content: end;
    text-align: right;

    .timestamp {
      text-align: right;
      margin-top: 2px;
    }

    .message-border {
      //background: #fd5631;
      //border-color: #fd6531;
      //color: #fff;
      background: #B9E7DD;
      border-color: #B9E7DD;
      color: #000;
      text-align: left;
    }
  }

  &.pending {
    .timestamp {
      opacity: 0.3;
    }
    .message-border {
      opacity: 0.3;
    }
  }
}

.badge {
  max-width: 100%;
  overflow-wrap: break-word;
  text-align: left;
}

.message {
  flex-grow: 1;

  &.clickable {
    cursor: pointer;
  }

  .timestamp {
    font-size: 10px;
  }

  .message-border {
    padding: 8px 10px 10px 10px;
    border-radius: 5px;
    border: 1px solid #eee;

    //border: 1px solid #f5f4f8;
    //background: #f5f4f8;

    border: 1px solid #E0E0E0;
    background: #E0E0E0;
    display: inline-block;
    overflow-wrap: break-word;
    max-width: 100%;
    white-space: pre-wrap;

    //color: #fff;
  }

  max-width: 70%;
  font-size: 14px;

  .files {
    margin-top: 10px;

    .file {
      margin-right: 5px;
      text-decoration: none;

      &:hover {
        color: #fff;
        background: #1f1b2d !important;
      }
    }
  }
}
</style>